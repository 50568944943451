import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from '@app/app.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_ROUTES } from '@app/app.routes';
import {
  provideRouter,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { CoreModule } from '@core/core.module';
import { environment } from '@environments/environment';
import { CustomPreloadingStrategy } from '@core/common/providers/custom-preloading-strategy';
import { authInterceptor } from '@core/interceptors';
import { provideServiceWorker } from '@angular/service-worker';

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(APP_ROUTES, withPreloading(CustomPreloadingStrategy), withRouterConfig({
        paramsInheritanceStrategy: 'always',
    })
    // withDebugTracing(),
    ),
    importProvidersFrom(CoreModule),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    }),
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000'
    })
],
}).then();
